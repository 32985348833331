import { gotoEvent, showMsg, elId, getQueryVariable, ERROR_MESSAGES } from './utils-module.js';

redirectToWebapp();

const scanCardInput = elId('scanCardInput');
const scanCardAct = elId('scanCardAct');
const attId = getQueryVariable('attId');
const partialUnlockTag = getQueryVariable('u');
const eventPrivateApiKey = getQueryVariable('pvId');
if (attId) {
  scanCardInput.value = attId;
}

function isValid(code) {
  return code.length > 4 && code.length <= 32;
}

function normalizeCode(code) {
  return code.replaceAll(' ', '');
}

function handler() {
  if (isValid(scanCardInput.value)) {
    gotoEvent(normalizeCode(scanCardInput.value), eventPrivateApiKey, partialUnlockTag);
  } else {
    showMsg(ERROR_MESSAGES.valid_att_code);
  }
}

scanCardAct.addEventListener('click', handler);

scanCardInput.addEventListener('keyup', (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    handler();
  }
});

/**
 * Redirect to the webapp when we have the url like this
 * https://storibox.com/?wr=wemn,0,JXFQ-TWPR-4323
 */
function redirectToWebapp() {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('wr')) {
    const wr = searchParams.get('wr');
    const [eventName, rawFr, attendeeId] = wr.split(',');
    const fr = rawFr === '1';
    window.location.replace(`${process.env.WEBAPP_URL}?eName=${eventName}&uId=${attendeeId}&fr=${fr}`);
  }
}
