function recallAfterOffline(XMLHttpRequest, fn) {
  if (XMLHttpRequest.readyState === 0 || XMLHttpRequest.readyState === 4) {
    // Network error (i.e. connection refused, access denied due to CORS, etc.)
    window.addEventListener(
      'online',
      () => {
        fn();
      },
      { once: true }
    );
  }
}

export async function sendFileToS3(
  file,
  { s3key, s3bucketUrl, s3fields, defaultImgPath, defaultFileType = 'image/jpeg' },
  { retryIfOffline = false } = {}
) {
  return new Promise(function exec(resolve) {
    const xhr = new XMLHttpRequest();

    xhr.addEventListener('load', () => resolve(finalPath()), false);

    xhr.addEventListener(
      'error',
      (err) => {
        if (retryIfOffline) {
          recallAfterOffline(err.currentTarget, () => exec(resolve));
        }
      },
      false
    );

    xhr.open('POST', s3bucketUrl, true); // MUST BE LAST LINE BEFORE YOU SEND

    xhr.send(prepareFormData());
  });

  function prepareFormData() {
    const fd = new FormData();

    fd.append('key', s3key);
    fd.append('acl', 'public-read');
    fd.append('success_action_status', '201');
    fd.append('Content-Type', getFileType());
    fd.append('X-Amz-Credential', s3fields['X-Amz-Credential']);
    fd.append('X-Amz-Algorithm', s3fields['X-Amz-Algorithm']);
    fd.append('X-Amz-Date', s3fields['X-Amz-Date']);
    fd.append('X-Amz-Security-Token', s3fields['X-Amz-Security-Token']);
    fd.append('Policy', s3fields.Policy);
    fd.append('X-Amz-Signature', s3fields['X-Amz-Signature']);
    fd.append('file', file);
    return fd;
  }

  function finalPath() {
    if (s3fields.bucket) {
      return `https://${s3fields.bucket}/${s3key}`;
    }
    return `${defaultImgPath}/${s3key}`;
  }

  function getFileType() {
    return file.type && file.type !== '' ? file.type : defaultFileType;
  }
}

export const initRequest = (serverUrl) => ({
  post: async (path, body = {}, { addAuth = false, retryIfOffline = false } = {}) => {
    let auth = null;
    if (addAuth) {
      auth = await getAuthToken();
    }
    return new Promise(function exec(resolve, reject) {
      const xhr = new XMLHttpRequest();

      xhr.responseType = 'json';

      xhr.addEventListener(
        'load',
        (event) => {
          resolve(event.currentTarget.response);
        },
        false
      );

      xhr.addEventListener(
        'error',
        (err) => {
          if (retryIfOffline) {
            recallAfterOffline(err.currentTarget, () => exec(resolve));
          } else {
            reject(err.currentTarget);
          }
        },
        false
      );

      xhr.open('POST', `${serverUrl}${path}`, true); // MUST BE LAST LINE BEFORE YOU SEND

      if (auth) {
        xhr.setRequestHeader('Authorization', auth);
      }

      xhr.send(JSON.stringify(body));
    });
  },
});

async function getAuthToken() {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCurrentUser();
    if (cognitoUser == null) {
      reject(new Error('unauthorized_user'));
      return;
    }
    cognitoUser.getSession((err, session) => {
      if (err || !session.isValid()) {
        const error = {};
        error.type = 'unauthorized_user';
        if (err) {
          error.innerError = err;
        }
        reject(error);
      } else {
        resolve(session.getIdToken().getJwtToken());
      }
    });

    function getCurrentUser() {
      if (!process.env.POOL_DATA_USER_ID) {
        throw new Error('POOL_DATA_USER_ID must be defined');
      }
      if (!process.env.POOL_DATA_CLIENT_ID) {
        throw new Error('POOL_DATA_CLIENT_ID must be defined');
      }
      if (!AmazonCognitoIdentity) {
        throw new Error('AmazonCognitoIdentity should be included');
      }
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: process.env.POOL_DATA_USER_ID,
        ClientId: process.env.POOL_DATA_CLIENT_ID,
      });
      return userPool.getCurrentUser();
    }
  });
}
