import { captureEvents } from '../../../packages/src/api/index.js';

export const ERROR_MESSAGES = {
  valid_att_code: 'Please enter the code printed on the back of your StoriCard.',
  empty_album: 'Your album is empty.',
  valid_email: 'Please enter a valid e-mail address',
};
const API_URL = process.env.BACKEND_API;
const OLD_WEBAPP = process.env.OLD_WEBAPP_URL;
const PAYPAL_ACTIVE = process.env.STORIBOX_PAYPAL_ACTIVE === 'true';

export function elId(id) {
  return document.getElementById(id);
}
const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Email address
function isEmail(email, multi) {
  let validEmail = true;
  if (multi) {
    const emails = email.split(',');
    if (emails && emails.length > 1) {
      $.each(emails, (index, value) => {
        if (!emailRegex.test(value)) {
          return false;
        }
        return undefined;
      });
    } else {
      validEmail = emailRegex.test(emails[0]);
    }
  } else {
    validEmail = emailRegex.test(email);
  }
  return validEmail;
}

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

function attendeeValidation({ attendeeUid, eventPrivateApiKey }, callback) {
  if (eventPrivateApiKey) {
    $.ajax({
      type: 'POST',
      url: `${API_URL}/attendee/check-and-group-uid-list`,
      data: JSON.stringify({
        attendee_uid_list: [attendeeUid],
        event_private_api_key: eventPrivateApiKey,
      }),
      dataType: 'json',
      success(resp) {
        if (resp.result === true) {
          fetchAttendee(attendeeUid, callback);
        } else {
          callback({ status: 'error', message: 'Invalid attendee code' });
        }
      },
    });
  } else {
    fetchAttendee(attendeeUid, callback);
  }
}

function fetchAttendee(attendeeUid, callback) {
  $.ajax({
    type: 'POST',
    url: `${API_URL}/attendee/retrieve`,
    data: JSON.stringify({
      attendee_uid: attendeeUid,
    }),
    dataType: 'json',
    success(resp) {
      callback(resp);
    },
  });
}

function getEvent(attendeeUid, callback) {
  const data = {
    key_type: 'attendee_uid',
    key_value: attendeeUid,
  };
  $.ajax({
    type: 'POST',
    url: `${API_URL}/event/list/retrieve`,
    data: JSON.stringify(data),
    dataType: 'json',
    success(resp) {
      callback(resp);
    },
  });
}

function partialUnlock(attendeeUid, eventPrivateApiKey, partialUnlockTag, callback) {
  const body = {
    attendee_uid: attendeeUid,
    event_private_api_key: eventPrivateApiKey,
    employee_secret_code: '',
    should_print: false,
    should_unlock: true,
    email_to_address: null,
    auth_required: true,
    phone_number: null,
    printer_pool_name: null,
    printable_photo_uid_array: null,
    price: null,
    payment_type: null,
    kioskId: null,
    partialUnlockTags: partialUnlockTag && partialUnlockTag !== 'undefined' ? partialUnlockTag : null,
    printing_count: 0,
  };

  $.ajax({
    type: 'POST',
    url: `${API_URL}/attendee/unlock-and-print`,
    data: JSON.stringify(body),
    dataType: 'json',
    success(resp) {
      callback(resp);
    },
  });
}

function updateAttendee(attendee_uid, email, name, event_name, app_url, callback) {
  const data = {
    attendee_uid,
    email_address: email,
    full_name: name,
    event_name,
    app_url,
  };
  $.ajax({
    type: 'POST',
    url: `${API_URL}/attendee/update-by-kiosk`,
    data: JSON.stringify(data),
    dataType: 'json',
    success(resp) {
      callback(resp.result);
    },
  });
}

export function joinAttendeeIds(attendee_uid_list, private_id, { apiUrl = API_URL, callback = () => {} } = {}) {
  const data = {
    attendee_uid_list,
    event_private_api_key: private_id,
  };
  $.ajax({
    type: 'POST',
    url: `${apiUrl}/attendee/check-and-group-uid-list`,
    data: JSON.stringify(data),
    dataType: 'json',
    success(resp) {
      callback(resp.result);
    },
  });
}

// eslint-disable-next-line no-unused-vars
function scanAgain() {
  $('#error').html('');
  $('#scanCardInput').val('');
  $('#scanCardInput').focus();
}

async function captureLoginEvent(attId, event, eventName = 'Login', email = '', name = '') {
  await captureEvents({
    EventSource: 'OldWebApp',
    EventName: eventName,
    EventType: 'info',
    EventCode: event.name,
    OrganisationId: event.organisation_id,
    EventId: event.id,
    EventFullName: event.full_name,
    AttendeeUid: attId,
    Email: email,
    UserFullName: name,
    Count: '1',
    EventDate__date: Date.now(),
  });
}

function openAlbum(attId, event) {
  window.location.href = redirectLink(attId, event);
}

function redirectLink(attId, event, isFromEmail = false) {
  const opts = event.opts && JSON.parse(event.opts);

  if (opts.enabledRedirectToWebapp) {
    let link = `${process.env.WEBAPP_URL}?eName=${event.name}&uId=${attId}`;
    if (isFromEmail) link += '&fr=false';
    return link;
  }

  let eventLink = `${OLD_WEBAPP}?attId=${attId}`;
  if (PAYPAL_ACTIVE) eventLink += '&attType=evSB2';

  return eventLink;
}

// eslint-disable-next-line no-extend-native
String.prototype.replaceAll = function (search, replacement) {
  const target = this;
  return target.replace(new RegExp(search, 'g'), replacement);
};

export function gotoEvent(attId, eventPrivateApiKey, partialUnlockTag = '') {
  showMsg('Loading...');
  attendeeValidation({ attendeeUid: attId, eventPrivateApiKey }, (resp) => {
    if (resp.status === 'ok') {
      if (resp.result) {
        getEvent(attId, (eventResp) => {
          showMsg('');
          if (eventResp.status === 'ok') {
            if (eventResp.result && eventResp.result.dataList.length > 0) {
              const eventParams = eventResp.result.dataList[0];
              const eventName = eventParams.name;
              if (partialUnlockTag?.length > 0) {
                partialUnlock(attId, eventParams.private_api_key, partialUnlockTag, () => {});
              }
              if (eventParams.opts) {
                const eventOptions = JSON.parse(eventParams.opts);
                if (eventOptions && eventOptions.enableEmailCollect === 1) {
                  if (resp.result.email_address === 'null' || resp.result.email_address === null) {
                    $('.att-email').slideDown();
                    $('#scanCardInput').removeClass('scan-card');
                    $('#scanCardAct').hide();
                    elId('saveAttEmail').onclick = function () {
                      const attendeeEmailVal = elId('attendeeEmail').value;
                      const name = elId('attendeeName').value;
                      if (attendeeEmailVal === '') {
                        captureAndRedirect(eventParams);
                      } else if (!isEmail(attendeeEmailVal, false)) {
                        showMsg(ERROR_MESSAGES.valid_email);
                      } else {
                        $('.att-email').slideUp();
                        captureLoginEvent(attId, eventParams, 'LoginUpdate', attendeeEmailVal, name);
                        updateAttendee(
                          attId,
                          attendeeEmailVal,
                          name,
                          eventName,
                          redirectLink(attId, eventParams, true),
                          () => {
                            openAlbum(attId, eventParams);
                          }
                        );
                      }
                    };
                  } else {
                    captureAndRedirect(eventParams);
                  }
                } else {
                  captureAndRedirect(eventParams);
                }
              }
            } else {
              showMsg(ERROR_MESSAGES.empty_album);
            }
          }
        });
      } else {
        showMsg(ERROR_MESSAGES.empty_album);
      }
    } else {
      showMsg(ERROR_MESSAGES.valid_att_code);
    }
    function captureAndRedirect(eventParams) {
      return captureLoginEvent(attId, eventParams).then(() => openAlbum(attId, eventParams));
    }
  });
}

export function showMsg(txt) {
  elId('msgCont').innerHTML = txt;
}
