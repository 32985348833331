import { initRequest, sendFileToS3 } from './request.js';

function haveAdminPerm(keyType) {
  return keyType === null;
}

function safeParseJSON(opts) {
  return (opts && JSON.parse(opts)) || {};
}

const restApi = initRequest(process.env.BACKEND_API);
const defaultImgPath = process.env.IMG_PATH;

export async function captureEvents(param) {
  const events = getEvents();
  if (!events || events.length === 0) return;
  return restApi.post('/activity/capture-event', events);

  function getEvents() {
    if (Array.isArray(param)) return param;
    return [param];
  }
}

export async function uploadFileToTmpStorage(file, { eventName, attendeeUId, keepOriginName = true }) {
  return getAccessToUploadInS3({ eventName, fileName: file.name, attendeeUId, keepOriginName })
    .then((resp) => resp.result)
    .then((result) =>
      sendFileToS3(file, {
        s3key: result.fields.key,
        s3bucketUrl: result.url,
        s3fields: result.fields,
        defaultImgPath,
      })
    );
}

async function getAccessToUploadInS3({ eventName, fileName, attendeeUId, keepOriginName = true }) {
  return restApi.post('/event/tmp-photo-upload-access/retrieve', {
    event_name: eventName,
    file_name: fileName,
    keep_origin_name: keepOriginName,
    attendee_uid: attendeeUId,
  });
}

export async function uploadSelfie(file, { attendeeId, eventName }) {
  return getAccessToUploadInS3Selfie(file.name)
    .then((resp) => resp.result)
    .then((result) =>
      sendFileToS3(file, {
        s3key: result.fields.key,
        s3bucketUrl: result.url,
        s3fields: result.fields,
        defaultImgPath,
      })
    )
    .then((imgSrc) => addToDBSelfie({ attendeeId, imgSrc, eventName }));
}

async function addToDBSelfie({ attendeeId, imgSrc, eventName }) {
  return restApi.post('/attendee/profile-photo/create', {
    attendee_uid: attendeeId,
    full_path: imgSrc,
    event_name: eventName,
  });
}

async function getAccessToUploadInS3Selfie(fileName) {
  return restApi.post('/photo/tmp-upload-access/retrieve', {
    file_relative_path: fileName,
  });
}

export async function searchBySelfie({
  eventOwnerApiKey,
  photoUrlList,
  faceMatchThreshold = 60,
  isKiosk = false,
  startDate,
  endDate,
}) {
  return restApi.post('/photo/search-by-face', {
    event_owner_api_key: eventOwnerApiKey,
    photo_url_list: photoUrlList,
    face_match_threshold: faceMatchThreshold,
    isKiosk,
    start_date: startDate,
    end_date: endDate,
  });
}

export async function findSelfiesByAttendeeId(attendeeId) {
  return restApi.post('/attendee/profile-photo/list/retrieve', {
    attendee_uid: attendeeId,
  });
}

export async function deleteSelfie(attendeeId, photoUId) {
  return restApi.post('/attendee/profile-photo/delete', {
    attendee_uid: attendeeId,
    photo_uid: photoUId,
  });
}

export async function findEvent({ eventName }) {
  const response = await restApi.post('/event/retrieve', {
    event_name: eventName,
  });

  if (response.result) {
    response.result.opts = safeParseJSON(response.result.opts);
  }

  return response;
}

export async function findEventByPrivateApiKey({ id }) {
  const response = await restApi.post('/event/list/retrieve', {
    key_type: 'event_private_api_key',
    key_value: id,
    max_size: 1,
    page_number: 1,
  });

  const event = response.result.dataList[0];
  event.opts = safeParseJSON(event.opts);
  return { result: event, status: response.status };
}

export async function regByWebappIdFirstStep1() {
  return restApi.post('/attendee/create/by-webapp-id-first/step-1');
}

export async function regByWebappIdFirstStep2({
  eventId,
  attendeeUid,
  attendeeName,
  attendeeEmail,
  attendeePhoneNumber,
  webAppURL,
}) {
  return restApi.post('/attendee/create/by-webapp-id-first/step-2', {
    eventId,
    attendeeUid,
    attendeeName,
    attendeeEmail,
    attendeePhoneNumber,
    webAppURL,
  });
}

export function joinAttendeeIds({ attendeeUIds, eventPrivateApiKey }) {
  return restApi.post('/attendee/check-and-group-uid-list', {
    attendee_uid_list: attendeeUIds,
    event_private_api_key: eventPrivateApiKey,
  });
}

export async function addVisitDateInfo({ eventName, uId, vDate, lat, long, lang, prePaid = false, email }) {
  return restApi.post('/genting-app/add-visit-date-info', {
    app_info_obj: {
      uId,
      vDate: vDate && prepareDate(),
      lat,
      long,
      lang,
      prePaid,
      email,
      eventName,
    },
  });

  function prepareDate() {
    const year = vDate.getFullYear();
    const month = padTo2Digits(vDate.getMonth() + 1);
    const day = padTo2Digits(vDate.getDate());
    return `${year}${month}${day}`;
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
}

export async function print(
  { eventId, attendeeUid, printerPoolName, photosUid, copies = 0, isStory = false },
  { keyType = null, keyValue = null } = {}
) {
  return restApi.post(determinePath(), prepareBody());

  function determinePath() {
    return haveAdminPerm(keyType) ? '/occo-owner/photo/print-with-queue' : '/photo/print-with-queue';
  }

  function prepareBody() {
    const body = {
      event_id: eventId,
      attUid: attendeeUid,
      printable_photo_uid_array: photosUid,
      printing_count: copies + 1, // NOTE: Should be at least 1 because we send the count of printing
      printer_pool_name: printerPoolName,
      isStory,
    };
    if (!haveAdminPerm(keyType)) {
      body.key_type = keyType;
      body.key_value = keyValue;
    }
    return body;
  }
}

export async function findAttendeeById(attendeeUid) {
  return restApi.post('/attendee/retrieve', {
    attendee_uid: attendeeUid,
  });
}

export async function findAttendeeByUIDAndEventId(attendeeUid, eventId) {
  return restApi.post('/event/attendee/retrieve', {
    attendeeUid,
    eventId,
  });
}

export async function findPhotos({
  eventId,
  attId,
  maxSize,
  pageNumber = null,
  keyType = null,
  keyValue = null,
  isVisible = null,
  startDate = null,
  endDate = null,
  minInterval = null,
  maxInterval = null,
  forKiosk = false,
  isCuratable = null,
  isCurated = null,
  isOriginal = null,
  printable = null,
  printed = null,
  showTotalCount = false,
  fileName = null,
  getFromTmp = null,
}) {
  return restApi.post(determinePath(), prepareBody());

  function determinePath() {
    return haveAdminPerm(keyType) ? '/occo-owner/photo/list/retrieve' : '/photo/list/retrieve';
  }

  function prepareBody() {
    const body = {
      event_id: eventId,
      max_size: maxSize,
      page_number: pageNumber,
      min_time_minutes_interval: minInterval,
      max_time_minutes_interval: maxInterval,
      is_kiosk: forKiosk,
      is_curatable: isCuratable,
      is_curated: isCurated,
      is_original: isOriginal,
      printable,
      printed,
      file_name: fileName,
      attendee_uid: attId,
      show_total_count: showTotalCount,
      get_from_tmp: getFromTmp,
    };
    if (typeof isVisible !== 'undefined' && isVisible !== null && isVisible !== '' && isVisible !== 'null') {
      body.is_visible = isVisible;
    }
    if (startDate) {
      body.start_date = startDate;
    }
    if (endDate) {
      body.end_date = endDate;
    }
    if (!haveAdminPerm(keyType)) {
      body.key_type = keyType;
      body.key_value = keyValue;
    }
    return body;
  }
}
